
import axios from 'axios';
import './home-page.css'
import { useEffect, useState } from 'react';

const sentenceUrl = 'https://api.vvhan.com/api/dailyEnglish?type=sj'
const weatherUrl = 'https://api.vvhan.com/api/weather?ip='
const ipUrl =  `https://ipinfo.io/json`
const bgImageUrl = 'https://api.vvhan.com/api/bing?type=json&rand=sj'
const placeholderBg="https://cn.bing.com/th?id=OHR.MountAbu_ZH-CN1348295593_1920x1080.jpg"

const getIpInfo = (url) => {
  return  axios(url).then(res => {
    return res.data.ip
  })
}

// const getSjImage = () => {
//   return  axios(bgImageUrl).then(res => {
//     return res.data.data
//   })
// }

function HomePage() {
  const [sentence,updateSentence] = useState({zh:'正在获取今日一句...'})
  const [weather,updateWether] = useState({type:'正在获取天气...'})
  // const [bgImage,updateBgImage] = useState({})

  const getWeather = (ip) => {
    axios(weatherUrl+ip).then(res => {
      console.log(res)
      if(res.data.success === false) {
        updateWether({type:'获取天气失败,'+res.data.message,success:false})
        return
      }
      updateWether(res.data)
    })
  }


  
  useEffect(()=>{
    axios(sentenceUrl).then(res => {
      console.log(res.data.data)
      updateSentence(res.data.data)
    }) 

    getIpInfo(ipUrl).then(ip => {
      getWeather(ip)
    })  
    
    // getSjImage().then(res => {
    //   console.log(res)
    //   updateBgImage(res)
    // })
   
  },[])

  return (
    <div className="container" style={{ backgroundImage: `url(${sentence.pic||placeholderBg})`}}>
     { !!weather.success ? <div className='weather'>
         {  `${weather.city} ${weather.data.date} ${weather.data.week} ${weather.data.low}~${weather.data.high} ` }
         {weather.data.type} 
         <div> {weather.tip} </div>
     </div> : null  } 

      <div className="overlay">
      </div>
      <div className='content' >
     
      {sentence.pic ? <img className="img" src={sentence.pic} alt="每日一图" /> : null}
       {/* <p className="img-title"> {bgImage.title} </p> */}
        <div className='sentence'> 
          <p> {sentence.en} </p>
          <p> {sentence.zh} </p>
        </div>
      </div>
    </div>
  );
}
export default HomePage
